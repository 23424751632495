<template>
    <div class="lhcBall">
      
        <div class="drawballs">
            <div :class="`${lotteryCode} ball ball-${parseInt(item)}`" v-for="(item,index) in lastDraw.slice(0, 6)" :key="index">{{item | padLeft(2)}}</div>
            
            <div class="add">+</div>
            <div :class="`${lotteryCode} ball ball-${parseInt(lastDraw[6])}`">{{lastDraw[6] | padLeft(2)}}</div>  
        </div>
        
        <div class="mappingBall_sx" v-if="currentInfo && currentInfo.display_info.zodiac != undefined">
           <div v-for="(item,index) in currentInfo.display_info.zodiac.slice(0,6)" :key="index" class="item">
                {{item}}
            </div>
            <div class="add"></div>
            <div class="item">{{currentInfo.display_info.zodiac[currentInfo.display_info.zodiac.length-1] }}</div>
        </div>
             
        <div class="mappingBall_wx" v-if="currentInfo && currentInfo.display_info.five_elements != undefined">
            <div v-for="(item,index) in currentInfo.display_info.five_elements.slice(0,6)" :key="index" class="item">
                {{item}}
            </div>
            <div class="add"></div>
            <div class="item">{{currentInfo.display_info.five_elements[currentInfo.display_info.five_elements.length-1] }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        lotteryCode:{
            type: String,
            required: true
        }, 
        lastDraw:Array | [],
        currentInfo:null   
    },
}
</script>
<style lang="scss" scoped>
.lhcBall{
    display: inline-grid;
}
.drawballs{
    display:grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 5px;
    margin-bottom: 5px;
    .ball{
        @include ballSize(30px);
        color: #fff;
        font-size: 17px;
       
    }     
}
.mappingBall_sx,
.mappingBall_wx{
    display:grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 5px;
    margin-bottom: 5px;
    .item{
        font-size: 12px;
        color: #646363;
       
    } 
}
.mappingBall_wx .item{
    color: #ff0000;
}

.add{
    font-size: 20px;
    color: #646363;
}
</style>