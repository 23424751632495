<template>
    <div class="content">
      <template v-if="dataList.length === 0">
        <div class="notify">还未到开奖时间</div>
      </template>
      <template v-else>
        <div class="child_menu">
          <div :class="`item ${selectItem==1 ? 'active' : ''}`" @click="selectItem=1">号码</div>
          <div :class="`item ${selectItem==2 ? 'active' : ''}`" @click="selectItem=2">总和</div>
        </div>
        <table>
          <!--- 歷史結果標題 --->
          <tr class="header-title">
            <td class="period">期数</td>
            <td v-if="selectItem==1" class="result">开奖号码</td>
            <td v-if="selectItem==2" class="sum">总和</td>
          </tr>
          <!--- 歷史結果列表 --->
          <tr v-for="(item,index) in dataList" :key="index" class="history_item">
            <td>
              <p class="period_txt">{{item.period_no[0]}}期</p>
              <p class="date_txt">{{item.draw_time[0] | formateDrawTimeDateMD}}</p>
            </td>
            <td  v-if="selectItem==1">
              <div class="ball-wrapper">
                <div :class="`${name} ball ball-${parseInt(num)} solid`"  v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber,name)" :key="index">{{num}}</div>
              </div>
            </td>
            <td  v-if="selectItem==2" class="bzh sum_result">
              <span>{{item.HundredTenSum[0]}}</span>
              <span :class="getUseColor(item.HundredTenSum[1])">{{item.HundredTenSum[1]}}</span>
              <span :class="getUseColor(item.HundredTenSum[2])">{{item.HundredTenSum[2]}}</span>
            </td>
            <td  v-if="selectItem==2" class="bgh sum_result">
              <span>{{item.HundredOneSum[0]}}</span>
              <span :class="getUseColor(item.HundredOneSum[1])">{{item.HundredOneSum[1]}}</span>
              <span :class="getUseColor(item.HundredOneSum[2])">{{item.HundredOneSum[2]}}</span>
            </td>
            <td  v-if="selectItem==2" class="zgh sum_result">
              <span>{{item.TenOneSum[0]}}</span>
              <span :class="getUseColor(item.TenOneSum[1])">{{item.TenOneSum[1]}}</span>
              <span :class="getUseColor(item.TenOneSum[2])">{{item.TenOneSum[2]}}</span>
            </td>
            <td  v-if="selectItem==2" class="total sum_result">
              <span>{{item.TotalSum[0]}}</span>
              <span :class="getUseColor(item.TotalSum[2])">{{item.TotalSum[2]}}</span>
              <span :class="getUseColor(item.TotalSum[1])">{{item.TotalSum[1]}}</span>
            </td>
          </tr>


          <template v-if="appendList.length > 0">
            <tr v-for="(item,index) in appendList" :key="'append-'+index" class="history_item">
              <td>
                <p class="period_txt">{{item.period_no[0]}}期</p>
                <p class="date_txt">{{item.draw_time[0] | formateDrawTimeDateMD}}</p>
              </td>
              <td  v-if="selectItem==1">
                <div class="ball-wrapper">
                  <div :class="`${name} ball ball-${parseInt(num)} solid`"  v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber,name)" :key="index">{{num}}</div>
                </div>
              </td>
              <td  v-if="selectItem==2" class="bzh sum_result">
                <span>{{item.HundredTenSum[0]}}</span>
                <span :class="getUseColor(item.HundredTenSum[1])">{{item.HundredTenSum[1]}}</span>
                <span :class="getUseColor(item.HundredTenSum[2])">{{item.HundredTenSum[2]}}</span>
              </td>
              <td  v-if="selectItem==2" class="bgh sum_result">
                <span>{{item.HundredOneSum[0]}}</span>
                <span :class="getUseColor(item.HundredOneSum[1])">{{item.HundredOneSum[1]}}</span>
                <span :class="getUseColor(item.HundredOneSum[2])">{{item.HundredOneSum[2]}}</span>
              </td>
              <td  v-if="selectItem==2" class="zgh sum_result">
                <span>{{item.TenOneSum[0]}}</span>
                <span :class="getUseColor(item.TenOneSum[1])">{{item.TenOneSum[1]}}</span>
                <span :class="getUseColor(item.TenOneSum[2])">{{item.TenOneSum[2]}}</span>
              </td>
              <td  v-if="selectItem==2" class="total sum_result">
                <span>{{item.TotalSum[0]}}</span>
                <span :class="getUseColor(item.TotalSum[2])">{{item.TotalSum[2]}}</span>
                <span :class="getUseColor(item.TotalSum[1])">{{item.TotalSum[1]}}</span>
              </td>
            </tr>
          </template>

        </table>

      </template>
    </div>
</template>
<script>
import {mapState} from "vuex";

export default {
    props:{
        dataList:Array,
        appendList:Array,
        name:String
    },
    methods:{
        getUseColor(string){
            if( string === '单' || string === '小' || string === '尾小'){
                return 'color-blue'
            }
            return 'color-red'
        },
    },
    data(){
        return{
            selectItem:1, //1:號碼 2:總和 3:特碼
        }
    }
}
</script>
<style lang="scss" scoped>
.notify{
  width: 100%;
  margin-top:5vh;
  color:#c4c4c4;
  letter-spacing: 3px;
  font-size:32px;
}
.content{
    .child_menu{
        display: flex;
        background-color: #fff;
        align-items: center;
        height:32px;
        .item{
            flex:1;
            color:#727272;
            font-size:12px;
            font-weight:bold;
            margin: 0 20px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            &.active{
                color: #c19162;
                border-bottom: 2px solid #c19162;
            }
        }
    }
    /*** 歷史列表 table ***/
    table{
        background: #fff;
        tr:nth-child(1){
            font-weight:bold;
            background:#fdefe0;
            color:#727272;
            height:36px;
            border-bottom: 0;
        } 
        tr{
            border-bottom: 1px solid #e3e3e3;
        }
        /*** 歷史列表 title ***/
        .header-title{
            background: #fff;
            height: 36px;
            font-weight: bold;
            font-size: 12px;
            td{
               
            }
            .time,.period{
                width: 30%;
            }
            
            .result{
                width: 70%;
            }
            .sum{
                width: auto;
            }
        }
        /*** 歷史列表 item ***/
        .history_item{
            height: 54px;
            font-size: 12px;
            color: #646363;
            .ball-wrapper{
                display: flex;
                justify-content: center;
                .ball{
                    @include ballSize(22px);     
                    margin-right: 2px;
                    font-size:12px;
                }
            }
            .sum_result{
                span{
                    width: 70px;
                    display: inherit;
                    &:nth-child(2), &:nth-child(3){
                        font-weight: 900;
                    }
                }
            }  
        }
    }  
}
.bzh,.bgh,.zgh,.total{
    border-left:1px solid #e3e3e3;
}

</style>
